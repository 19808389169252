import React from 'react';
import './App.css';
import logo from './streamutt-white.png'
import ReactCountryFlag from "react-country-flag";
import netflixLogo from './netflix.png'
import primevideoLogo from './primevideo.png'
import hululogo from './hululogo.png'
import amazon from './amazon.png'
import hbogo from './hbogo.png'
import hbonow from './hbonow.png'
import itunes from './itunes.png'
import iosDownload from './iosDownload.svg'
import {isAndroid} from 'react-device-detect';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-60234062-2');
ReactGA.pageview(window.location.pathname + window.location.search);

const axios = require('axios');


class Header extends React.Component{
    render() {
        return(
            <div className={"header"}><img alt={"Streamutt Logo"} src={logo}/></div>
        )
    }
}

class RecentItems extends React.Component{
  divStyle = {
    backgroundImage: 'url('+this.props.imageURL+')',
  };

  sendTitle(title){
      this.props.whenclicked(title)
  }

  render() {
    return(
        <div className={"MoviePoster"} style={this.divStyle}  onClick={() => this.sendTitle(this.props.title)}/>
    )
  }
}

class RecentList extends React.Component{

  // latestNetflixJSON = JSON.parse(latestNetflix);

  constructor(props){
    super(props);
    this.state ={
        titles: [],
        ready:false
    };
  }

  componentWillMount() {
      let _this = this;

      axios.get('/api/latest_netflix')
          .then(function (response) {
              // handle success
              _this.setState({
                  titles: response.data.ITEMS,
              });
          });
  }


    readIMDB = (title) => {
        this.props.title(title)
    };


    render() {
        let posters = this.state.titles.map((item, key) =>
            <RecentItems key={key} imageURL={item.image} title={item.title} whenclicked={this.readIMDB}/>);

    return (
        <div className={"MoviesHero"}>
          <Header/>
          <p>New On Netflix</p>
          <div className={"MoviesRow"}>
            {posters}
          </div>
        </div>
    )
  }
}

class OfferRow extends React.Component{

    render() {

        let returnPackages = this.props.package.map((pack,key) =>
            <a key={key} className={"package"} href={pack.href}>
                <span className={"price"}>{pack.price===undefined?'In Subscription':pack.price}<span className={"currency"}>{pack.price===undefined?null:pack.currency}</span></span>
                <span className={"quality"}>{pack.quality}</span>{pack.payment!=='flatrate'?<span className={"type"}>{pack.payment}</span>:null}
            </a>);

        return(
            <div className={"offers-row"}>
                {this.props.name === 'Netflix'? <img alt="Netflix Logo" src={netflixLogo}/>:null}
                {this.props.name === 'Amazon Prime Video'? <img alt="Amazon Prime Logo" src={primevideoLogo}/>:null}
                {this.props.name === 'Hulu'? <img alt="Hulu Logo" src={hululogo}/>:null}
                {this.props.name === 'Amazon Video'? <img alt="Amazon Video Logo" src={amazon}/>:null}
                {this.props.name === 'HBO Now'? <img alt="HBO Now Logo" src={hbonow}/>:null}
                {this.props.name === 'HBO Go'? <img alt="HBO GO Logo" src={hbogo}/>:null}
                {this.props.name === 'Apple iTunes'? <img alt="Itunes Logo" src={itunes}/>:null}
                <div className={"packages"}>
                    {returnPackages}
                </div>
            </div>
        )
    }
}


class Offers extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            wantedProviders:[
                "Netflix",
                "Amazon Prime Video",
                "Hulu",
                "Amazon Video",
                "HBO Now",
                "HBO Go",
                "Apple iTunes",
            ],
            payment:{
                flatrate:'Stream',
                rent:'Rental',
                buy:'Buy',
            },
            country:'en_US',
            data: {},
            providers:[],
            ajaxCompleted:false,
            rows:[],
            readyToShow:false,
            runtime:null
        }
    }

    componentWillMount() {
        this.updateRows();
    }

    updateRows() {

        let _this = this;
        let country = this.checkLocalStorageCountry();

        axios.get('/api/avail?title='+_this.props.title+"&country="+country+'&year='+_this.props.year+'&record='+_this.props.record)
            .then(function (response) {

                    // handle success
                    _this.setState({
                        ajaxCompleted:true,
                        data:response.data.records,
                        providers:response.data.providers
                    },_this.pushRows);

            });
    };

    pushRows() {
        let _this = this;

        if(this.state.ajaxCompleted){

            if(_this.state.data.items.length > 0){

                // run through each offer available for the film
                let crossRef = new Promise (() =>

                    _this.state.data.items[0].offers.forEach(function (offer) {

                        // then run through each provider available for country
                        _this.state.providers.forEach(function (provider) {

                            // if the current offer ID = the current provider ID
                            if (provider.id === offer.provider_id) {

                                // look for channel in channels array
                                let wantIt = _this.state.wantedProviders.indexOf(provider.clear_name);

                                // if channel exists in array, then...
                                if (wantIt !== -1) {

                                    if(_this.state.rows[provider.clear_name]){

                                        let   newPckg = {
                                            quality:        offer.presentation_type,
                                            price:          offer.retail_price,
                                            currency:       offer.currency,
                                            payment:        offer.monetization_type,
                                            href:           offer.urls.standard_web
                                        };


                                        _this.state.rows[provider.clear_name].package.push(newPckg)

                                    }else{
                                        _this.state.rows[provider.clear_name] = {
                                            package: [{
                                                quality:        offer.presentation_type,
                                                price:          offer.retail_price,
                                                currency:       offer.currency,
                                                payment:        offer.monetization_type,
                                                href:           offer.urls.standard_web
                                            }]
                                            ,
                                            id:             offer.provider_id,
                                            clear_name:     provider.clear_name,
                                            include:true,
                                        };

                                        // _this.state.rows.push(newObj);

                                    }
                                }
                            }
                        })
                    })
                );


                crossRef.then(
                    _this.setState({
                        readyToShow:true
                    })
                )

            }

        }
    }

    handleDropdown(event) {
        ReactGA.event({
            category: 'Web-Country',
            action: 'Switched Country',
            label: event.target.value
        });

        localStorage.setItem('country',event.target.value);

        this.setState({
            country:event.target.value,
            readyToShow:false,
            ajaxCompleted:false,
            data:{},
            providers:[],
            rows:[]
        }, () => {this.updateRows()}
        );
    }

    checkLocalStorageCountry(){
        if(localStorage.getItem('country') !== null){
            return localStorage.getItem('country');
        }else{
            return this.state.country;
        }
    }

    returnRows (){

        let rows = Object.keys(this.state.rows).map((item, key) =>
            <OfferRow     key={key}
                          name={this.state.rows[item].clear_name}
                          package={this.state.rows[item].package.map((pack)=> pack)}
            />
        );

        if(this.state.readyToShow){
            return rows
        }else{
            return <div className={"loading"}><span/><span/><span/></div>
        }
    }

    render() {

        return (

            <div className={"availability-box"}>
                <span className={"availability-line"}>
                    <select defaultValue={this.checkLocalStorageCountry()} onChange={(event) => this.handleDropdown(event)}>
                        <option value={"pt_BR"}>Brazil </option>
                        <option value={"en_CA"}>Canada </option>
                        <option value={"fr_FR"}>France </option>
                        <option value={"de_DE"}>Germany </option>
                        <option value={"it_IT"}>Italy </option>
                        <option value={"ja_JP"}>Japan </option>
                        <option value={"en_MY"}>Malaysia </option>
                        <option value={"es_MX"}>Mexico </option>
                        <option value={"en_NZ"}>New Zealand </option>
                        <option value={"pt_PT"}>Portugal </option>
                        <option value={"en_SG"}>Singapore </option>
                        <option value={"en_ZA"}>South Africa </option>
                        <option value={"ko_KR"}>South Korea </option>
                        <option value={"es_ES"}>Spain </option>
                        <option value={"en_GB"}>United Kingdom </option>
                        <option value={"en_US"}>United States </option>
                        {/*<option value={"ms_BN"}>Brunei Darussalam </option>*/}
                        {/*<option value={"bg_BG"}>Bulgaria </option>*/}
                        {/*<option value={"km_KH"}>Cambodia </option>*/}
                        {/*<option value={"en_29"}>Caribbean </option>*/}
                        {/*<option value={"es_CR"}>Costa Rica </option>*/}
                        {/*<option value={"hr_HR"}>Croatia </option>*/}
                        {/*<option value={"cs_CZ"}>Czech Republic </option>*/}
                        {/*<option value={"da_DK"}>Denmark </option>*/}
                        {/*<option value={"es_DO"}>Dominican Republic </option>*/}
                        {/*<option value={"es_EC"}>Ecuador </option>*/}
                        {/*<option value={"es_SV"}>El Salvador </option>*/}
                        {/*<option value={"et_EE"}>Estonia </option>*/}
                        {/*<option value={"am_ET"}>Ethiopia </option>*/}
                        {/*<option value={"fo_FO"}>Faroe Islands </option>*/}
                        {/*<option value={"fi_FI"}>Finland </option>*/}
                        {/*<option value={"smn_FI"}>Finland </option>*/}
                        {/*<option value={"ka_GE"}>Georgia </option>*/}
                        {/*<option value={"el_GR"}>Greece </option>*/}
                        {/*<option value={"kl_GL"}>Greenland </option>*/}
                        {/*<option value={"es_GT"}>Guatemala </option>*/}
                        {/*<option value={"es_HN"}>Honduras </option>*/}
                        {/*<option value={"zh_HK"}>Hong Kong S.A.R. </option>*/}
                        {/*<option value={"hu_HU"}>Hungary </option>*/}
                        {/*<option value={"is_IS"}>Iceland </option>*/}
                        {/*<option value={"en_IN"}>India </option>*/}
                        {/*<option value={"fa_IR"}>Iran </option>*/}
                        {/*<option value={"en_IE"}>Ireland </option>*/}
                        {/*<option value={"ur_PK"}>Islamic Republic of Pakistan </option>*/}
                        {/*<option value={"he_IL"}>Israel </option>*/}
                        {/*<option value={"en_JM"}>Jamaica </option>*/}
                        {/*<option value={"ar_JO"}>Jordan </option>*/}
                        {/*<option value={"kk_KZ"}>Kazakhstan </option>*/}
                        {/*<option value={"sw_KE"}>Kenya </option>*/}
                        {/*<option value={"ar_KW"}>Kuwait </option>*/}
                        {/*<option value={"ky_KG"}>Kyrgyzstan </option>*/}
                        {/*<option value={"lo_LA"}>Lao P.D.R. </option>*/}
                        {/*<option value={"lv_LV"}>Latvia </option>*/}
                        {/*<option value={"ar_LB"}>Lebanon </option>*/}
                        {/*<option value={"ar_LY"}>Libya </option>*/}
                        {/*<option value={"de_LI"}>Liechtenstein </option>*/}
                        {/*<option value={"lt_LT"}>Lithuania </option>*/}
                        {/*<option value={"fr_LU"}>Luxembourg </option>*/}
                        {/*<option value={"zh_MO"}>Macao S.A.R. </option>*/}
                        {/*<option value={"mk_MK"}>Macedonia</option>*/}
                        {/*<option value={"dv_MV"}>Maldives </option>*/}
                        {/*<option value={"mt_MT"}>Malta </option>*/}
                        {/*<option value={"mn_MN"}>Mongolia </option>*/}
                        {/*<option value={"sr_ME"}>Montenegro </option>*/}
                        {/*<option value={"ar_MA"}>Morocco </option>*/}
                        {/*<option value={"ne_NP"}>Nepal </option>*/}
                        {/*<option value={"nl_NL"}>Netherlands </option>*/}
                        {/*<option value={"es_NI"}>Nicaragua </option>*/}
                        {/*<option value={"ha_NG"}>Nigeria </option>*/}
                        {/*<option value={"ar_OM"}>Oman </option>*/}
                        {/*<option value={"es_PA"}>Panama </option>*/}
                        {/*<option value={"es_PY"}>Paraguay </option>*/}
                        {/*<option value={"zh_CN"}>People's Republic of China </option>*/}
                        {/*<option value={"es_PE"}>Peru </option>*/}
                        {/*<option value={"fil_PH"}>Philippines </option>*/}
                        {/*<option value={"pl_PL"}>Poland </option>*/}
                        {/*<option value={"fr_MC"}>Principality of Monaco </option>*/}
                        {/*<option value={"es_PR"}>Puerto Rico </option>*/}
                        {/*<option value={"ar_QA"}>Qatar </option>*/}
                        {/*<option value={"en_PH"}>Republic of the Philippines </option>*/}
                        {/*<option value={"ro_RO"}>Romania </option>*/}
                        {/*<option value={"ru_RU"}>Russia </option>*/}
                        {/*<option value={"rw_RW"}>Rwanda </option>*/}
                        {/*<option value={"ar_SA"}>Saudi Arabia </option>*/}
                        {/*<option value={"wo_SN"}>Senegal </option>*/}
                        {/*<option value={"sr_RS"}>Serbia </option>*/}
                        {/*<option value={"sk_SK"}>Slovakia </option>*/}
                        {/*<option value={"sl_SI"}>Slovenia </option>*/}
                        {/*<option value={"si_LK"}>Sri Lanka </option>*/}
                        {/*<option value={"se_SE"}>Sweden </option>*/}
                        {/*<option value={"fr_CH"}>Switzerland </option>*/}
                        {/*<option value={"syr_SY"}>Syria </option>*/}
                        {/*<option value={"zh_TW"}>Taiwan </option>*/}
                        {/*<option value={"tg_TJ"}>Tajikistan </option>*/}
                        {/*<option value={"th_TH"}>Thailand </option>*/}
                        {/*<option value={"en_TT"}>Trinidad and Tobago </option>*/}
                        {/*<option value={"ar_TN"}>Tunisia </option>*/}
                        {/*<option value={"tr_TR"}>Turkey </option>*/}
                        {/*<option value={"tk_TM"}>Turkmenistan </option>*/}
                        {/*<option value={"ar_AE"}>U.A.E. </option>*/}
                        {/*<option value={"uk_UA"}>Ukraine </option>*/}
                        {/*<option value={"es_UY"}>Uruguay </option>*/}
                        {/*<option value={"uz_UZ"}>Uzbekistan </option>*/}
                    </select>
                    <h3> Direct Links <span>Click to open offer</span></h3>
                </span>
                <div className={"iconRows"}>
                    {this.returnRows()}
                </div>
            </div>
        );
    }
}


class NetflixRow extends React.Component{

    constructor(props){
        super(props);
        this.state={
            imdbID:undefined,
            netflix:[],
            netflixReady:[],
            codes:['fr','br','ca','us','gb','jp','de','kr','id','no','it','es','my','sg']
        }
    }

    renderFlags(){
        let _this = this;
        if(this.props.netflix !== undefined && JSON.stringify(this.props.netflix).length > 2){

                return this.props.netflix.RESULT.country.map((country,key)=>
                    <ReactCountryFlag
                        key={key}
                        code={country.ccode}
                        styleProps={{
                            display: _this.state.codes.indexOf(country.ccode)!==-1?' ':'none',
                            fontSize:"30px",
                            marginRight:"5px"
                        }} />);


        }else{
            return <div className={"loading"}><span/><span/><span/></div>
        }
    }

    render() {

        return (
            <div className={"availability-row"}>
                <div><h3>Netflix Countries</h3></div>
                <div className={"flags-row"}>
                    {this.renderFlags()}
                </div>
            </div>
        )
    }
}

class Providers extends React.Component{

    render() {

        let netflix = <NetflixRow channel={"netflix"} netflix={this.props.netflix} imdbID={this.props.imdbID}  />;

        return (
            <div className={"availability-box"}>
                <Offers record={this.props.record} year={this.props.year} title={this.props.title}/>
                {netflix}
            </div>
        );
    }
}



class PreviewBoxes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tmdbID: this.props.tmdbID,

            //AJAX ONES
            imdbID: undefined,
            channels:undefined,
            imdbData:undefined,
            netflix:undefined,

            delayedClass: false,
            avail: [],
            selected: false,
        }
    }

    componentDidUpdate() {

        let _this = this;
        if(this.state.delayedClass === false && this.state.selected === true){
            setTimeout(function () {
                _this.setState({
                    delayedClass: true,
                });
                window.scroll({top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
            }, 200);
        }

    }

    sendAction(bool,id){
        this.props.actionProp(bool,id);
    }

    openTitle(event){
        let _this = this;

        event.stopPropagation();

         this.setState({
                selected:true,
            });

        this.sendAction(true,this.state.tmdbID);

        if(this.state.imdbID === undefined) {
            axios.get("/api/info?id=" + this.props.tmdbID + "&record=" + _this.props.record)
                .then(function (response) {
                    _this.setState({
                        imdbID: response.data.imdbID,
                        netflix: response.data.UNOGS
                    });
                });
        }

    }

    closeTitle(event){
        event.stopPropagation();
        this.setState({
            selected:false,
            delayedClass:false,
            netflix:undefined,
            imdbData:undefined,
            channels:undefined,
            imdbID:undefined
        });

        this.sendAction(false,null);
    }

    cover(){
        let backdropStyle = {
            backgroundImage: 'url(https://image.tmdb.org/t/p/w780//'+this.props.backdrop+')'
        };

        if(this.state.selected){
            return <div className={this.state.delayedClass===false?'backdrop':'backdrop slideIn'}
                        style={backdropStyle}>
                <div className={"backdrop-gradient"}>
                    <button onClick={(event) => this.closeTitle(event)}>X</button>
                </div>
            </div>
        }
    }

    divStyle = {
        backgroundImage: 'url(https://image.tmdb.org/t/p/w342//'+this.props.poster+')',
    };

    additionalInfo(){
        if(this.state.netflix){
            let data =this.state.netflix.RESULT;

            return  (
                <div className={"additional-info"}>
                    <span>{data.imdbinfo.genre}</span>
                    <span>Runtime: {data.imdbinfo.runtime}</span>
                </div>
            )
        }
    }

    info(){
        if(this.state.delayedClass){

            let data = this.props.info;
            let year = this.props.record==='movie'?data.release_date.split('-')[0]:data.first_air_date.split('-')[0];

            return (
                <div className={"information-box"}>
                    <h2>{this.props.title} <span>({data.release_date?data.release_date.split('-')[0]:data.first_air_date?data.first_air_date.split('-')[0]:null})</span></h2>
                    <p>{data.overview}</p>
                    {this.additionalInfo()}
                    <Providers year={year} record={this.props.record} term={this.props.term} title={this.props.title} imdbID={this.state.imdbID} netflix={this.state.netflix} channels={this.state.channels} country={"en_US"}/>
                </div>)
        }
    }

    render() {
        return (
            <div className={"preview-item "+this.props.additionalClass}
                 onClick={(event) => this.openTitle(event)}>
                {this.cover()}
                <div className={"preview-box"} style={this.divStyle}>
                    <div className={"preview-content"}/>
                </div>
                {this.info()}
            </div>
    );
    }
}

class SearchContainer extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            typing: false,
            record: 'movie',
            searchBy: 'title',
            term: undefined,
            preview: [],
            opened: false,
            selectedID:null
        }
    };

    //TODO: Need to figure out how to run search when user clicks cover title
    // componentDidUpdate() {
    //     if(this.props.clickedCoverTitle!==undefined){
    //         this.setState({
    //             term:this.props.clickedCoverTitle
    //         },() => this.handleSearch(this.state.term));
    //     }
    // }

    handleTyping(event){
        this.setState({term:event.target.value})
    }


    // bulk of the search
    handleSearch(event,recordParam,byParam){

        //variables for later
        let _this = this;
        let term = this.state.term;
        let record = recordParam?recordParam:this.state.record;
        let searchBy = byParam?byParam:this.state.searchBy;


        //if user stopped typing after 500ms, do its thing
        if(term.length > 0){

            event.preventDefault();
            this.setState({
                preview:[],
                opened:false
            });

            ReactGA.event({
                category: 'Web-'+record,
                action: 'Search By '+searchBy,
                label: term
            });

            axios.get("/api/search?term="+term+"&record="+record+"&searchby="+searchBy)
                .then(function (response) {
                    switch (searchBy) {
                        case 'person':
                            if(response.success===undefined){

                                let cast = response.data.cast;
                                let crew = response.data.crew;

                                let crewLength = crew?crew:0;
                                let castLength = cast?cast:0;

                                _this.setState({
                                    typing:false,
                                    term: term,
                                    preview: castLength>crewLength?cast:crew
                                });
                            }
                            break;
                        default:
                            _this.setState({
                                typing:false,
                                term: term,
                                preview: response.data.results
                            });
                            break;
                    }
                })

        }
    }

    changeRecord(event){
        let value = event.target.value;
        this.setState({record:value});
        if(this.state.term){
            this.handleSearch(event,value,null)
        }
    }

    changeBy(event){
        let value = event.target.value;
        this.setState({searchBy:value});
        if(this.state.term){
            this.handleSearch(event,null,value)
        }
    }

    readActionProp = (bool,id) =>{
        if(id!==null){
            this.setState({
                opened:bool,
                selectedID:id
            })
        }else{
            this.setState({
                opened:bool
            })
        }
    };

    readClass = (idToCompare) => {
        if(this.state.opened){
            switch (this.state.selectedID) {
                case idToCompare:
                    return 'active';
                default:
                    return 'd-none';
            }
        }else{
            return ' ';
        }
    };

    render() {
        let previewBoxes = this.state.preview.map((item, key) =>
            <PreviewBoxes key={key}
                          info={item}
                          title={this.state.record==='movie'?item.original_title:item.original_name}
                          poster={item.poster_path}
                          backdrop={item.backdrop_path}
                          tmdbID={item.id}
                          record={this.state.record}
                          country={this.state.country}
                          actionProp={this.readActionProp}
                          opened={this.state.opened}
                          selectedID={this.state.selectedID}
                          additionalClass={this.readClass(item.id)}
            />
        );

        return(
        <div className={"mainContainer"}>
            <div className="titleDropdown">
                <h2 style={{display:'inline-block'}}>Search for a </h2>
                <div>
                <select onChange={(event) => this.changeRecord(event,null,null)}>
                    <option defaultValue={"movie"} value={"movie"}>Movie</option>
                    <option value={"tv"}>TV Show</option>
                </select>
                <h2>by </h2>
                <select onChange={(event) => this.changeBy(event,null,null)}>
                    <option defaultValue={"title"} value={"title"}>Title</option>
                    <option value={"person"}>Person</option>
                </select>
                </div>
            </div>
            <form className={"search-box"} method={"post"} onSubmit={(event) => this.handleSearch(event)}>
                <input type={"search"} name={"title-search"} placeholder={"Searching "+(this.state.record==='tv'?'tv shows':'movies')+" by "+this.state.searchBy} onChange={(event) => this.handleTyping(event)}/>
                <input type={"submit"} value={"Search"}/>
            </form>
            <div className={this.state.opened?'previews active':'previews'}>
                {previewBoxes}
            </div>
        </div>
    )
  }
}

class AppScreen extends React.Component{


    componentDidMount()
    {
        // Call our fetch function below once the component mounts
        this.callBackendAPI()
            .then(res => console.log(res.connection))
            .catch(err => console.log(err));
    }

    // Fetches our GET route from the Express server. (Note the route we are fetching matches the GET route from server.js
    callBackendAPI = async () => {
        const response = await fetch('/api/check_connection');
        const body = await response.json();

        if (response.status !== 200) {
            throw Error(body.message)
        }
        return body;
    };

    constructor(props) {
        super(props);
        this.state={
            clickedCover:null,
            appLinkDisplay:'flex'
        }
    }

    readTitle = (title) => {
        console.log(title);
        this.setState({clickedCover:title})
    };

    handleLink = () => {
        ReactGA.event({
            category: 'App Link',
            action: 'Clicked Link',
        });

        window.location='https://apps.apple.com/us/app/streamutt/id1475317889';
    };

    appLink = () => {
        let _this = this;

        function handleClose() {
            localStorage.setItem('appRowClosed2','true');
            _this.setState({appLinkDisplay:'none'})
        }

        let alreadyClosed = localStorage.getItem('appRowClosed2');

        if(!alreadyClosed){
            if (!isAndroid) {
                return (
                    <div className={'appLinkRow'} style={{display:this.state.appLinkDisplay}}>
                        <div style={{padding:'0 20px'}}>
                            <p>Streamutt is now available on the iOS App Store and Google Play Store!</p>
                            <div style={{display:'flex',justifyContent:'center'}}>
                                <a href={'/#'} onClick={()=>this.handleLink()} target={'_blank'}>
                                    <img style={{marginTop:10}} alt={'Download iOS App'} src={iosDownload}/>
                                </a>
                                <a href='https://play.google.com/store/apps/details?id=com.streamuttapp&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                    <img style={{maxHeight:60}} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png'/>
                                </a>
                            </div>
                        </div>
                        <button onClick={() => handleClose()}>
                            &times;
                        </button>
                    </div>
                )
            }
        }
    };

    render() {

        return(
            <div className="App">
                {this.appLink()}
                <div className={"Out Now"}>
                    <RecentList title={this.readTitle}/>
                </div>
                <SearchContainer clickedCoverTitle={this.state.clickedCover}/>
                <p className="supportRow">For Support, <a href={"mailto:ntoporcov@me.com"}>Click Here</a> to Contact Developer</p>
            </div>
        )
    }
}

class PrivacyPage extends React.Component{
    render() {
        return(
            <body style={{background: "#292929",display:"flex",justifyContent:"center",fontFamily:"-apple-system, Helvetica, sans-serif"}}>

            <div style={{maxWidth: "600px",paddingTop:"40px",color:"white",textAlign:"center"}}>
                <img alt="Streamutt Logo" style={{maxWidth: "300px"}} src={logo}/>
                    <h1>Privacy Policy</h1>
                    <p>Nicolas Toporcov built the Streamutt app as a Free app. This SERVICE is provided by Nicolas Toporcov at no cost and is intended for use as is.
                        This page is used to inform visitors regarding my policies with the collection, use, and disclosure of Personal Information if anyone decided to use my Service.
                        I <strong>DO NOT</strong>, collect, therefore don'' use or disclose any personal information.</p>
                    <h2>Information Collection and Use</h2>
                    <p>The app does use third party services but they do not have any interaction with your end, therefre are not able collect information used to identify you.</p>
                    <h2>Log Data</h2>
                    <p>I want to inform you that whenever you use my Service, in a case of an error in the app I collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing my Service, the time and date of your use of the Service, and other statistics.</p>

                    <h2>Service Providers</h2>

                    <p> I may employ third-party companies and individuals due to the following reasons:</p>

                    <h2>Security</h2>

                    <p> I value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and I cannot guarantee its absolute security.</p>

                    <h2>Links to Other Sites</h2>

                    <p>This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by me. Therefore, I strongly advise you to review the Privacy Policy of these websites. I have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>

                    <h2>Children’s Privacy</h2>

                    <p>These Services do not address anyone under the age of 13. I do not knowingly collect personally identifiable information from children under 13. In the case I discover that a child under 13 has provided me with personal information, I immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact me so that I will be able to do necessary actions.</p>

                    <h2>Changes to This Privacy Policy</h2>

                    <p>I may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. I will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</p>
            </div>
            </body>
    )
    }
}

function App() {

    function getParams(location) {
        const searchParams = new URLSearchParams(location.search);
        return {
            page: searchParams.get('page') || '',
        };
    }

    const params = getParams(window.location);

    if(params.page==='privacy'){
        return(
            <PrivacyPage/>
        )
    }else{
        return (
            <AppScreen/>
        );
    }
}

export default App;
